var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { ref: "dropdown", staticClass: "dropdown-wrap" }, [
      _c(
        "div",
        {
          staticClass:
            "dropdown-title w-100 d-flex justify-content-center align-items-center cursor-pointer p-relative",
          class: [_vm.archived ? "bg-light" : "bg-primary-light"],
          staticStyle: { "font-size": "2rem" },
          on: { click: _vm.makeActive }
        },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("i", {
            staticClass: "i-chevron-down chevron-btn",
            class: { inverted: _vm.active },
            staticStyle: { position: "absolute", right: "5px" }
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.active
        ? _c(
            "div",
            { staticClass: "dropdown", class: { active: _vm.active } },
            [
              _c(
                "perfect-scrollbar",
                {
                  staticClass: "scroll-wrap",
                  attrs: { "infinite-wrapper": "" }
                },
                [
                  _c(
                    "nav",
                    { staticClass: "nav flex-column" },
                    [_vm._t("default")],
                    2
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }