<template>
  <div>
    <div class="dropdown-wrap" ref="dropdown">
      <div
        class="dropdown-title w-100 d-flex justify-content-center align-items-center cursor-pointer p-relative"
        :class="[archived ? 'bg-light' : 'bg-primary-light']"
        style="font-size: 2rem"
        @click="makeActive"
      >
        <slot name="title"></slot>
        <i
          class="i-chevron-down chevron-btn"
          style="position: absolute; right: 5px"
          :class="{ inverted: active }"
        ></i>
      </div>
      <div class="dropdown" :class="{ active }" v-if="active">
        <perfect-scrollbar class="scroll-wrap" infinite-wrapper>
          <nav class="nav flex-column">
            <slot></slot>
          </nav>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: [Array, Object],
    title: String,
    archived: Boolean
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    makeActive() {
      this.active = !this.active;
      if (this.active) {
        this.$emit("active", this.active);
      }
    }
  }
};
</script>

<style scoped>
.scroll-wrap {
  max-height: 200px;
}
.bg-primary-light {
  background-color: rgba(2, 140, 255, 0.27);
}
.inverted {
  transform: rotateZ(-180deg);
}
.chevron-btn {
  transition: transform 200ms;
}
</style>
